var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid gap-1 items-center" },
    [
      _c(
        "vs-checkbox",
        {
          staticClass: "smaller-checkbox",
          model: {
            value: _vm.autoReload,
            callback: function ($$v) {
              _vm.autoReload = $$v
            },
            expression: "autoReload",
          },
        },
        [_vm._v(_vm._s(_vm.$t("auto_reload")))]
      ),
      _vm.autoReload
        ? _c(
            "div",
            { staticClass: "flex items-center ml-2 text-xs text-gray-600" },
            [
              _c("span", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t("reloading_in"))),
              ]),
              _c("div", { staticClass: "relative" }, [
                _c("svg", { attrs: { width: "24", height: "24" } }, [
                  _c("circle", {
                    attrs: {
                      cx: "12",
                      cy: "12",
                      r: "10",
                      fill: "none",
                      stroke: "#e2e8f0",
                      "stroke-width": "2",
                    },
                  }),
                  _c("circle", {
                    attrs: {
                      cx: "12",
                      cy: "12",
                      r: "10",
                      fill: "none",
                      stroke: _vm.progressColor,
                      "stroke-width": "2",
                      "stroke-dasharray": _vm.circumference,
                      "stroke-dashoffset": _vm.progress,
                      transform: "rotate(-90 12 12)",
                    },
                  }),
                  _c(
                    "text",
                    {
                      staticClass: "text-xs transition-colors",
                      attrs: {
                        x: "50%",
                        y: "50%",
                        "dominant-baseline": "middle",
                        "text-anchor": "middle",
                        fill: _vm.progressColor,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.remainingTime) + " ")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }