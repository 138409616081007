<template>
  <div class="grid gap-1 items-center">
    <vs-checkbox
      class="smaller-checkbox"
      v-model="autoReload">{{ $t('auto_reload') }}</vs-checkbox>

    <div v-if="autoReload" class="flex items-center ml-2 text-xs text-gray-600">
      <span class="mr-1">{{ $t('reloading_in') }}</span>
      <div class="relative">
        <svg width="24" height="24">
          <!-- Fundo do círculo -->
          <circle
            cx="12"
            cy="12"
            r="10"
            fill="none"
            stroke="#e2e8f0"
            stroke-width="2"
          />
          <!-- Progresso -->
          <circle
            cx="12"
            cy="12"
            r="10"
            fill="none"
            :stroke="progressColor"
            stroke-width="2"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="progress"
            transform="rotate(-90 12 12)"
          />
          <!-- Texto dentro do círculo -->
          <text
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
            class="text-xs transition-colors"
            :fill="progressColor"
          >
            {{ remainingTime }}
          </text>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoReloadIndicator',
  props: {
    reloadTime: {
      type: Number,
      default: 60 // Default
    }
  },
  data() {
    return {
      autoReload: false,
      remainingTime: this.reloadTime,
      intervalId: null,
      circumference: 2 * Math.PI * 10
    }
  },
  computed: {
    formattedTime() {
      return `${this.remainingTime.toString().padStart(2, '0')}`
    },
    progress() {
      return this.circumference * (1 - this.remainingTime / this.reloadTime)
    },
    progressColor() {
      const warningThreshold = Math.floor(this.reloadTime * 0.2) // 20% do tempo total
      return this.remainingTime <= warningThreshold ? '#ef4444' : '#3b82f6'
    }
  },
  watch: {
    autoReload(newVal) {
      if (newVal) {
        this.startCountdown()
      } else {
        this.stopCountdown()
      }
    }
  },
  methods: {
    startCountdown() {
      this.remainingTime = this.reloadTime
      this.intervalId = setInterval(() => {
        this.remainingTime -= 1

        if (this.remainingTime <= 0) {
          this.$emit('reload-triggered')
          this.resetCountdown()
        }
      }, 1000)
    },
    stopCountdown() {
      clearInterval(this.intervalId)
      this.resetCountdown()
    },
    resetCountdown() {
      this.remainingTime = this.reloadTime
    },
    shutDownAuto(){
      this.autoReload = false
    }
  },
  beforeDestroy() {
    this.stopCountdown()
  }
}
</script>
