<template>
  <div class="grid grid-cols-12 gap-2">
    <vx-card class="col-span-12">
      <div class="flex items-center p-2 flex-wrap gap-4">
        <div class="flex-grow w-full">
          <select-suggestion
              :max="20"
              :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
              @selected-model="loadDataGrid"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('avaliation')"
              v-model="questionnaire"
              :appendClearOption="true"
              @clearSelection="clearFilters"
              ref="select_questionnaire"/>
        </div>
        <div class="flex gap-1 ml-auto" v-if="questionnaire">
          <AutoReloadIndicator :reload-time="30" @reload-triggered="reloadDataGrid" ref="autoReloader" />
        </div>
      </div>
      <!--
      <div class="w-full flex gap-2 justify-end">
        <vs-button
          class="float-right mr-2"
          @click="openPanel()">{{ $t('action.open') }}</vs-button>
      </div>
      -->
    </vx-card>

    <!-- INFORMAÇÕES QUESTIONÁRIO -->
     <!--
    <vx-card v-if="questionnaire" class="col-span-12">
      <questionnaire-information :questionnaire="questionnaire"/>
    </vx-card>
    -->
    <vx-card v-if="questionnaire && dataGrid" class="col-span-12">
      <questionnaire-event-indicators :indicators="dataGrid" :show_events="false" :show_title="false"/>
    </vx-card>

    <vx-card v-if="questionnaire && dataGrid" class="col-span-12">
      <e-charts autoresize :options="dataToPie" ref="pie" auto-resize />
    </vx-card>
  </div>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/bar'

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import AutoReloadIndicator from '@/components/AutoReloadIndicator'
import QuestionnaireInformation from '../../questionnaires/events/QuestionnaireInformation.vue'
import QuestionnaireEventIndicators from '../../questionnaires/events/QuestionnaireEventIndicators.vue'

import screenfull from 'screenfull'

import Logo from '@/layouts/components/Logo.vue'

import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    //
    ECharts,
    SelectSuggestion,
    Logo,
    AutoReloadIndicator,
    QuestionnaireInformation,
    QuestionnaireEventIndicators,
  },
  props: {

  },

  data: () => ({
    service: null,
    questionnaire: null,
    dataGrid: null,

  }),
  computed: {
    validateForm() {
      return (this.questionnaire != null && !this.isEmpty(this.questionnaire.id))
    },
    noData() {
      return (this.dataGrid === null)
    },
    dataToPie(){
      return this.getDataToPie()
    },
  },
  watch: {

  },
  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    reloadDataGrid(){
      this.loadDataGrid(this.questionnaire)
    },
    loadDataGrid(data){
      if(data && data.id){
        this.$vs.loading()
        this.service.getStatistics(data.id).then(
        response => {
          this.dataGrid = response
        },
        error => {this.notifyError(this.$vs,this.$t('nao-foi-possivel-carretar-data-grid'))}
       ).finally(() => {this.$vs.loading.close()})
      }else{
        this.dataGrid = null
      }
    },
    clearFilters() {
      this.$refs['select_questionnaire'].reset()
      this.$refs.autoReloader.shutDownAuto()
    },
    openPanel(){
      this.$router.push(`/questionnaires/${this.questionnaire.id}/dashboard/panel`)
    },
    getDataToPie(){
      return {
        title: {
          text: 'Avaliação',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow' // Melhora a visualização ao passar o mouse
          },
          formatter: (params) => {
            const total = this.dataGrid.scheduled_answer_count;
            return `${params[0].name}<br/>
                    ${params[0].seriesName}: ${params[0].value}
                    (${((params[0].value / total) * 100).toFixed(2)}%)`;
          }
        },
        xAxis: {
          type: 'category',
          data: ['Prevista(s)', 'Não Finalizada(s)', 'Finalizada(s)'],
          axisTick: { alignWithLabel: true }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Total',
            type: 'bar',
            barWidth: '50%',
            data: [
              {
                value: this.dataGrid.scheduled_answer_count,
                itemStyle: { color: '#3498DB' },
                label: { show: true, position: 'top', formatter: (params) => `${((params.value / this.dataGrid.scheduled_answer_count) * 100).toFixed(2)}%` }
              },
              {
                value: this.dataGrid.answers_in_progress,
                itemStyle: { color: '#FF9F43' },
                label: { show: true, position: 'top', formatter: (params) => `${((params.value / this.dataGrid.scheduled_answer_count) * 100).toFixed(2)}%` }
              },
              {
                value: this.dataGrid.finished_answers,
                itemStyle: { color: '#28C76F' },
                label: { show: true, position: 'top', formatter: (params) => `${((params.value / this.dataGrid.scheduled_answer_count) * 100).toFixed(2)}%` }
              },
            ]
          }
        ]
      }


    }, //End getDataToPie

  },
  beforeMount() {
    this.service = ContentQuestionnaireService.build(this.$vs)
  },
  beforeDestroy() {

  },
  mounted() {

  },

}
</script>

<style>

</style>
